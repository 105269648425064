:host {
  display: block;
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .rectangle-wrapper {
      display: flex;
      flex-direction: row;
      gap: 2px;
      .inline {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
      }
      .rectangle {
        width: 2rem;
        height: 0.5rem;
        background-color: rgba($color: #f5f4f4, $alpha: 2);
      }
      .selected {
        background-color: #00a1af;
      }
      .line {
        height: 2rem;
        width: 2px;
        background-color: #1bb28e;
      }
    }
  }
}
