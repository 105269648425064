import { Component, Prop, h, State } from '@stencil/core';

@Component({
  tag: 'appose-proficiency-bar',
  styleUrl: 'components.scss',
  shadow: true,
})
export class Components {
  @Prop() skillLevelCount: number = 5;
  @Prop() neededSkillLevel: number;
  @Prop() activeSkillLevel: number;
  @Prop() colors: string[] = ['#B4E1E7', '#8DD9DF', '#30BBC6', '#00A1AF', '#0D8896'];

  @State() skillLevels: number[] = [];

  componentWillLoad() {
    if (this.skillLevels.length == 0) {
      while (this.skillLevels.length < this.skillLevelCount) {
        this.skillLevels.push(this.skillLevels.length + 1);
      }
    }
  }

  render() {
    return (
      <div>
        <div class="wrapper">
          <div class="rectangle-wrapper">
            {this.skillLevels.map((level: number, index: number) => {
              if (level <= this.activeSkillLevel) {
                console.log('level: ' + level + ' activeSkillLevel: ' + this.activeSkillLevel + ' needed ' + this.neededSkillLevel);
                return (
                  <div class="inline">
                    <div class="rectangle selected" style={{ backgroundColor: this.colors[index] }}></div>
                    {level == this.neededSkillLevel ? <div class="line"></div> : null}
                  </div>
                );
              } else {
                return (
                  <div class="inline">
                    <div class="rectangle"></div>
                    {level == this.neededSkillLevel ? <div class="line"></div> : null}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}
